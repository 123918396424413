'use client';

import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState
} from 'react';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { msg } from '@/services/isomorphic/I18NService';
import IStylable from '../../traits/IStylable';

import { Icon, IconTypes } from '../Icon';

import S from './style.module.scss';
import { general_close } from "@/lang/__generated__/ahnu/general_close";

export interface IAlertProps extends PropsWithChildren, IStylable {
  /**
   * The variant of this Alert.
   */
  variant?: 'primary' | 'warning' | 'error';

  /**
   * The theme this Alert should adapt to, either `light` or `dark`.
   *
   * Defaults to `light`.
   */
  theme?: 'dark' | 'light';

  /**
   * If this Alert should be visible. When changed to `false`, the
   * Alert will hide itself with an animation.
   *
   * Defaults to `true`.
   */
  visible?: boolean;

  /**
   * If this Alert should include a close button that hides it on click.
   *
   * Defaults to `false`.
   */
  closable?: boolean;

  /**
   * The vertical alignment of the close button.
   * Will have no effect if the `closable` prop is falsy.
   *
   * Defaults to `up`.
   */
  closeButtonAlignment?: 'up' | 'center' | 'down';

  /**
   * The icon to display at the left side of the Alert.
   * If not supplied, no icon will be displayed.
   */
  icon?: IconTypes;

  /**
   * The vertical alignment of the icon.
   * Will have no effect if the `icon` prop is not supplied.
   *
   * Defaults to `up`.
   */
  iconAlignment?: 'up' | 'center' | 'down';
}

/**
 * Simple component to display Alert messages; i.e. Contextual feedback messages
 * for typical user actions.
 *
 * Similar to [Bootstrap alerts](https://getbootstrap.com/docs/5.3/components/alerts/).
 */
export const Alert: FunctionComponent<IAlertProps> = ({
  variant = 'primary',
  theme = 'light',
  visible = true,
  closable = false,
  closeButtonAlignment = 'up',
  icon,
  iconAlignment = 'up',
  className,
  id,
  style,
  children
}) => {
  const [isVisible, setVisible] = useState<boolean>(visible);

  // This effect will change the `isVisible` state when the
  // prop changes from the outside.
  useEffect(() => {
    setVisible(visible);
  }, [visible]);

  return (
    <div
      id={id}
      style={style}
      className={classes(S.alert, className, S[variant], S[theme], {
        [S.hidden]: !isVisible
      })}
    >
      <div className={S.contentWrapper}>
        {/* Icon */}
        {icon && (
          <div className={classes(S.iconContainer, S[iconAlignment])}>
            <Icon className={S.icon} icon={icon} />
          </div>
        )}

        <div className={S.mainContent}>{children}</div>

        {/* Close icon */}
        {closable && (
          <div
            className={classes(S.closeIconContainer, S[closeButtonAlignment])}
          >
            <Icon
              className={S.closeIcon}
              icon={IconTypes.Close}
              onClick={() => setVisible(false)}
              ariaLabel={msg(general_close)}
            />
          </div>
        )}
      </div>
    </div>
  );
};
