import { Nullable } from '@/type-utils';
import {
  Children,
  FunctionComponent,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  isValidElement
} from 'react';

/**
 * A reconstruction attempt of a react element.
 * Tries to reconstruct the props and the name of the component.
 */
interface IReconstructedChild {
  /** Key of the child. */
  key: string;

  /** Name of the original component ('div', 'CustomComponent', etc). */
  name: Nullable<string>;

  /** Reference to the component. (FunctionComponent or the class). */
  type: Nullable<FunctionComponent>;

  /** Props that were passed to the element. */
  props: Record<string, unknown>;

  /** The ReactNode itself, can be used directly as a child in a render. */
  node: ReactNode;
}

/**
 * Reconstructs the React child nodes into an array of objects that
 * describe the React components that created them.
 *
 * For HTML nodes (div, span) the name will be the node name.
 * For children that are ReactText (direct text inside of children), the name
 * will be `text`.
 * For fragments, the name will be `fragment`.
 *
 * @example ```tsx
 * const children = [
 *  <Element1 prop />,
 *  <Element2 hello="world" />
 * ];
 *
 * reconstructChildren(children) === [{
 *   name: 'Element1',
 *   type: Element1, // Function
 *   props: {
 *     prop: true,
 *   },
 *   node: ReactNode
 * }, {
 *   name: 'Element2',
 *   type: Element2,
 *   props: {
 *     hello: 'world',
 *   },
 *   node: ReactNode
 * }]
 * ```
 *
 * USE WITH CAUTION! In production, the function names are mangled, and
 * therefore the .displayName or (Function.name) gets mangled as well, turning
 * into something like "o", "M", "j". It works properly for class components.
 * In order to check function components correctly, one has to either:
 * 1. Set MyComponent.displayName = 'MyComponent';
 * 2. Don't compare the name, but rather the type against the function component itself:
 *
 * @example ```tsx
 * const reconstructed = reconstructChildren(children);
 * // Will work if `MyComponent.displayName = 'MyComponent'` is set explicitly
 * reconstructed.find(child => child.name === 'MyComponent');
 *
 * // Will work all the time! Emphasis on how we're using `type`,
 * // and how `MyComponent` is not a string but a function component
 * reconstructed.find(child => child.type === MyComponent);
 * ```
 *
 * @param children - React children.
 * @returns A reconstructed array of children.
 */
export const reconstructChildren = (
  children: ReactNode
): Array<IReconstructedChild> => {
  return Children.toArray(children).map((child) => {
    const component = child as IReconstructedChild;
    return {
      key: component.key,
      name:
        // Function Component
        component?.type?.displayName ||
        // Function Component, but using the Function.name property
        component?.type?.name ||
        // If a ReactText node is provided
        ((typeof component === 'string' || typeof component === 'number') &&
          'text') ||
        // A wild guess, but a React fragment internally uses a symbol for identification
        (typeof component?.type === 'symbol' && 'fragment') ||
        // For HTML5 elements, type is the name of the element (div, span)
        (component?.type as unknown as string),
      type: component?.type,
      props: component?.props || {},
      node: component as ReactNode
    };
  });
};

/**
 * Custom type guard that determines if a given value is a React Component
 * instance of the supplied constructor.
 *
 * @example
 *
 * ```tsx
 *    <Wizard>
 *        <WizardFlow name="find-order" />
 *            <WizardStep name="enter-order-id" />
 *            <WizardStep name="verify-account" />
 *        </WizardFlow>
 *
 *        <WizardStep name="select-items" />
 *        <WizardStep name="finish-return" />
 *    </Wizard>
 *
 *
 * ```
 *
 * ```ts
 * for (const child of wizardChildren) {
 *   if (isComponentOfType(WizardFlow, child)) {
 *    // Do WizardFlow stuff
 *   } else if (isComponentOfType(WizardStep, child)) {
 *     // Do WizardStep stuff
 *   } else {
 *     throw new Error('Child is neither a WizardFlow or WizardStep.');
 *   }
 * }
 * ```
 *
 * @param constructor - The component constructor to use.
 * @param value - The value to check.
 * @returns A value of `true` if the supplied value is indeed an instance of the component in question.
 */
export const isComponentOfType = <P>(
  constructor: JSXElementConstructor<P>,
  value: unknown
): value is ReactElement<P, JSXElementConstructor<P>> => {
  const e = value as ReactElement;

  if (!isValidElement(e)) {
    return false;
  }

  if (typeof e.type === 'string') {
    // Element is a string, not a WizardFlow
    return false;
  }

  if (e.type.name === constructor.name) {
    return true;
  }

  return false;
};
