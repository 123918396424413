'use client';

/**
 * @file
 * `SkipRenderOnClient` conditionally skips hydrating children by removing them
 * from the DOM _before the first client render_.
 *
 * Code borrowed from: https://gist.github.com/OliverJAsh/e9a588e7e907101affe1a7696a25b1fd.
 */

import IStylable from '@/react/components/traits/IStylable';
import { useIsFirstRender } from '@/react/hooks/useIsFirstRender';
import { useId, type FC, type PropsWithChildren } from 'react';

interface ISkipRenderOnClientProps extends PropsWithChildren<IStylable> {
  /**
   * A function that returns a boolean indicating if the children should be
   * rendered on the client.
   * @returns `true` if the children should be rendered on the client, `false` otherwise.
   */
  shouldRenderOnClient: () => boolean;
}

/**
 * When using React's server-side rendering, we often need to render components
 * on the server even if they are conditional on the client e.g. hidden based on
 * window width.
 *
 * In order for hydration to succeed, the first client render must
 * match the DOM (which is generated from the HTML returned by the server),
 * otherwise we will get hydration mismatch errors. This means the component
 * must be rendered again during the first client render.
 *
 * However, hydration is expensive, so we really don't want to pay that penalty
 * only for the element to be hidden or removed immediately afterwards.
 *
 * For example, imagine we have two components: one for mobile and one for
 * desktop. Usually we would render both components on the server and on the
 * client (to avoid hydration mismatch errors) and toggle their visibility using
 * CSS. This means we would be hydrating both components even though only one of
 * them is currently shown to the user.
 *
 * `SkipRenderOnClient` conditionally skips hydrating children by removing them
 * from the DOM _before the first client render_. Removing them before ensures
 * hydration is successful and there are no hydration mismatch errors.
 *
 * Following on from the example above, this is how we would apply
 * `SkipRenderOnClient`:.
 *
 * ```tsx
 * <SkipRenderOnClient shouldRenderOnClient={() => window.innerWidth <= 500}>
 *   <MyMobileComponent className={styles.showOnMobile} />
 * </SkipRenderOnClient>
 * <SkipRenderOnClient shouldRenderOnClient={() => window.innerWidth > 500}>
 *   <MyDesktopComponent className={styles.showOnDesktop} />
 * </SkipRenderOnClient>
 * ```
 */
export const SkipRenderOnClient: FC<ISkipRenderOnClientProps> = ({
  style,
  className,
  children,
  shouldRenderOnClient
}) => {
  // Generate a unique ID for this component.
  const id = useId();

  // Check for the present of the window object to determine if we are on the
  // client.
  const isClient = typeof window !== 'undefined';

  // Use a hook to determine if this is the first render.
  const isFirstRender = useIsFirstRender();

  // If this is the first render and we are on the client, and the component
  // should not render on the client, remove the children from the DOM.
  if (isClient && isFirstRender && !shouldRenderOnClient()) {
    const el = document.getElementById(id);
    if (el !== null) {
      el.innerHTML = '';
    }
  }

  // If we are on the client and the component should not render on the client,
  // we return a falsy value to prevent the children from being rendered.
  const shouldRender = isClient ? shouldRenderOnClient() : true;

  return (
    <span style={style} className={className} id={id}>
      {shouldRender && children}
    </span>
  );
};
