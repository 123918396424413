'use client';

import { useCallback, useRef } from 'react';

/**
 * A utility hook that returns a `setTimeout`-like function with the
 * difference that invoking it automatically cancels the previous
 * scheduled callback if it hasn't fired yet (a.k.a "debouncing").
 *
 * Note: The returned function can only "debounce" one callback at
 * a time. As a result, if you require the ability to debounce multiple
 * callbacks simultaneously, use multiple instances of this hook.
 * @returns A `setTimeout`-like function.
 * @example
 * const debounce = useDebounce();
 * // later in some event handler...
 * debounce(5000, () => console.log(data))
 *
 */
export function useDebounce(): (
  delayInMs: number,
  callbackFn: () => unknown
) => void {
  const ref = useRef<number>();

  return useCallback((delayInMs: number, callbackFn: () => unknown): void => {
    clearTimeout(ref.current);
    ref.current = window.setTimeout(callbackFn, delayInMs);
  }, []);
}
