/* eslint-disable react-hooks/rules-of-hooks -- Many hooks here are called
conditionally, but shouldn't vary between renders. */

'use client';

import {
  useRouterEventManager,
  type IRouterEventManager
} from '@/react/providers/router-events/useRouterEventManager';
import { InvalidStateError } from '@/utils/errors';
import {
  useRouter as useAppRouter,
  useParams,
  usePathname,
  useSearchParams
} from 'next/navigation';
import { useCallback, useMemo } from 'react';

/** Options for the `push` and `replace` methods of the router. */
export interface IRouterActionOptions {
  /**
   * Controls scrolling to the top of the page after navigation.
   *
   * Defaults to `true`.
   */
  scroll?: boolean;

  /**
   * Update the path of the current page without rerunning `getStaticProps`,
   * `getServerSideProps` or `getInitialProps` (Pages Router).
   *
   * On the App Router, will only update the window's URL without triggering
   * a re-render on any component.
   *
   * Defaults to `false`.
   */
  shallow?: boolean;
}

/**
 * Describes a DECA Router: An object that handles linking and routing.
 *
 * Its API is a subset of the Pages Router's API.
 * @see https://nextjs.org/docs/pages/api-reference/functions/use-router#router-object
 */
export interface IRouter {
  /**
   * The path for current route file that comes after /pages (Pages Router) or
   * /app (App Router).
   */
  pathname: string;

  /**
   * The query string parsed to an object, _including dynamic route parameters_.
   * It will be an empty object during prerendering if the page doesn't use
   * Server-side Rendering (Pages Router) or Dynamic Rendering (App Router).
   *
   * Defaults to `{}`.
   */
  query: Record<string, string | Array<string>>;

  /**
   * The path as shown in the browser including the search params and respecting
   * the trailingSlash configuration. BasePath and locale are not included.
   */
  asPath: string;

  /** The current locale. */
  locale: string;

  /**
   * Navigates to the specified URL. Unlike `next/link`, `push` is used to
   * programmatically navigate to another page.
   *
   * @param url - The URL to navigate to.
   * @param options - Optional object with configuration options.
   */
  push: (
    /**
     * The URL to navigate to.
     * @see https://nodejs.org/api/url.html#legacy-urlobject
     */
    url: URL | string,

    /** Optional object with configuration options. */
    options?: IRouterActionOptions
  ) => void;

  /**
   * Navigates to the specified URL without adding a new URL entry into the
   * history stack.
   *
   * @param url - The URL to navigate to.
   * @param options - Optional object with configuration options.
   */
  replace: (
    /**
     * The URL to navigate to.
     * @see https://nodejs.org/api/url.html#legacy-urlobject
     */
    url: URL | string,

    /** Optional object with configuration options. */
    options?: IRouterActionOptions
  ) => void;

  /**
   * Navigate back in history. Equivalent to clicking the browser’s back button.
   * It executes `window.history.back()`.
   */
  back: () => void;

  /**
   * Navigate forward in history. Equivalent to clicking the browser’s forward
   * button. It executes `window.history.forward()`.
   */
  forward: () => void;

  /**
   * Reload the current URL. Equivalent to clicking the browser’s refresh
   * button. It executes `window.location.reload()`.
   */
  reload: () => void;

  /**
   * Contains functions to create event listeners.
   *
   * Supported events:
   * - `routeChangeStart`: Fires when a route starts to change
   * - `routeChangeComplete`: Fires when a route changed completely.
   */
  events: IRouterEventManager;
}

/**
 * A hook that adapts the App Router to {@link IRouter}'s API.
 *
 * @returns An {@link IRouter} usable with the App Router.
 */
const useAppRouterAdapter = (): IRouter => {
  const appRouter = useAppRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const dynamicParams = useParams();

  const events = useRouterEventManager();

  const query = useMemo(
    () => ({
      ...dynamicParams,
      ...(searchParams && Object.fromEntries(searchParams.entries()))
    }),
    [dynamicParams, searchParams]
  );

  const push: IRouter['push'] = useCallback(
    (url, options) => {
      const { shallow = false, scroll = false } = options ?? {};

      if (shallow) {
        // https://nextjs.org/docs/app/building-your-application/routing/linking-and-navigating#using-the-native-history-api
        window.history.pushState(null, '', url);
      } else {
        const urlString = url instanceof URL ? url.toString() : url;

        appRouter.push(urlString, { scroll });
      }
    },
    [appRouter]
  );

  const replace: IRouter['push'] = useCallback(
    (url, options) => {
      const { shallow = false, scroll = false } = options ?? {};

      if (shallow) {
        // https://nextjs.org/docs/app/building-your-application/routing/linking-and-navigating#using-the-native-history-api
        window.history.replaceState(null, '', url);
      } else {
        const urlString = url instanceof URL ? url.toString() : url;

        appRouter.replace(urlString, { scroll });
      }
    },
    [appRouter]
  );

  const back = useCallback(() => {
    appRouter.back();
  }, [appRouter]);

  const forward = useCallback(() => {
    appRouter.forward();
  }, [appRouter]);

  const reload = useCallback(() => {
    window.location.reload();
  }, []);

  const asPath = useMemo(
    () => pathname + (searchParams ? `?${searchParams.toString()}` : ''),
    [pathname, searchParams]
  );

  const locale = useMemo(() => {
    if (!dynamicParams) {
      // When used in the Pages Router, `dynamicParams` will be `null` on the
      // first render.
      //
      // See https://nextjs.org/docs/app/api-reference/functions/use-params#returns
      return 'en-US';
    }

    const langParam = dynamicParams.lang;

    if (!langParam) {
      throw new InvalidStateError(
        'Cannot get locale in router: The [lang] dynamic param is missing ' +
          'in the URL.'
      );
    }

    if (!(typeof langParam === 'string')) {
      throw new InvalidStateError(
        'Cannot get locale in router: The [lang] dynamic param in the ' +
          'the current URL is malformed.'
      );
    }

    return langParam;
  }, [dynamicParams]);

  // Memoize the returned object so it isn't reassembled on every render.
  // This is to be able to use the returned object in dependency arrays for
  // hooks like `useEffect` and the like.
  const router: IRouter = useMemo(() => {
    return {
      pathname: pathname ?? '',
      asPath,
      query,
      locale,
      push,
      replace,
      back,
      forward,
      reload,
      events
    };
  }, [
    pathname,
    asPath,
    query,
    locale,
    push,
    replace,
    back,
    forward,
    reload,
    events
  ]);

  return router;
};

/**
 * Hook that allows access to the {@link IRouter DECA Router object}. Compatible
 * with both the Pages and App Routers.
 *
 * @returns The router object, as an {@link IRouter `IRouter`}.
 */
export const useRouter = (): IRouter => {
  // Do NOT conditionally return the pages router here (or any hook for that
  // matter). It causes all sorts of trouble with routing.
  return useAppRouterAdapter();
};
