import { createContext } from 'react';

/** Describes the arguments a router event handler will get passed. */
export type RouterEventArgs<T extends RouterEventName> = T extends
  | 'routeChangeStart'
  | 'routeChangeComplete'
  ? [string]
  : Array<any>;

/** Describes the arguments a router event handler. */
export type RouterEventHandler<T extends RouterEventName> = (
  ...events: RouterEventArgs<T>
) => void;

/** Describes valid router event names. */
export type RouterEventName = 'routeChangeStart' | 'routeChangeComplete';

/**
 * An object for managing router event listeners.
 */
export interface IRouterEventManager {
  /**
   * Creates an event handler.
   *
   * @param eventName - The name of the event to listen for.
   * @param callback - The callback to execute when the event is triggered.
   */
  on: <T extends RouterEventName>(
    eventName: T,
    callback: RouterEventHandler<T>
  ) => void;

  /**
   * Removes an event handler.
   *
   * @param eventName - The name of the event to remove the listener for.
   * @param callback - The callback to remove.
   */
  off: <T extends RouterEventName>(
    eventName: T,
    callback: RouterEventHandler<T>
  ) => void;
}

export const RouterEventManagerContext = createContext<IRouterEventManager>({
  on: () => {},
  off: () => {}
});
RouterEventManagerContext.displayName = 'RouterEventManagerContext';
