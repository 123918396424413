import {
  CHROMATIC_MOCK_STACK_TRACE,
  isChromatic
} from '@/utils/chromatic-utils';
import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';

/** Constructor parameters for {@link RenderError}. */
export interface IRenderErrorParams {
  /**
   * The name of the component that threw the error.
   * @example 'MyComponent'
   */
  readonly componentStack?: ReadonlyArray<string> | undefined;
  /**
   * The error that was thrown.
   * @example new Error('Something went wrong')
   */
  readonly error: unknown;
  /**
   * Whether the error occurred during hydration.
   */
  readonly inHydration?: boolean | undefined;
  /**
   * The time when the error occurred, in milliseconds since the page loaded.
   */
  readonly timestamp?: number | undefined;
  /**
   * The time when the error occurred, in milliseconds since the Unix epoch.
   */
  readonly date?: number | undefined;
}

/**
 * An error that is thrown when a component fails to render.
 *
 * Although it returns a new object, this class effectively
 * decorates an existing error with additional context.
 */
export class RenderError extends Error {
  /**
   * The stack of components that were rendering when the error occurred.
   */
  public readonly componentStack: ReadonlyArray<string> | undefined;

  /**
   * Whether the error occurred during hydration.
   */
  public readonly inHydration: boolean;

  /**
   * The time when the error occurred, in milliseconds since the page loaded.
   */
  public readonly timestamp: number;

  /**
   * The time when the error occurred, in milliseconds since the Unix epoch.
   */
  public readonly date: number;

  /**
   * The original error that was thrown.
   */
  public readonly error: unknown;

  /** @inheritdoc */
  public constructor({
    componentStack,
    error,
    inHydration,
    timestamp,
    date
  }: IRenderErrorParams) {
    if (error instanceof Error) {
      super(error.message, { cause: error.cause });
      this.name = error.name;
      this.stack =
        (typeof __isolatedContext__ !== "undefined") && isChromatic()
          ? CHROMATIC_MOCK_STACK_TRACE
          : error.stack;
    } else {
      super(undefined, { cause: error });
      this.name = 'Unknown';
      this.stack = undefined;
    }

    this.error = error;
    this.componentStack = componentStack;
    this.inHydration = inHydration ?? false;
    this.timestamp = timestamp ?? performance.now();
    this.date = date ?? Date.now();
  }
}
