/**
 * @file Contains type definitions for all the available breakpoints.
 * Also exports available breakpoints, their pixel values,
 * and useful mapping between different breakpoint variants.
 */
import { ReactNode } from 'react';

import {
  breakpointXxs,
  breakpointXs,
  breakpointSm,
  breakpointMd,
  breakpointLg,
  breakpointXl
} from '@/styles/variables.module.scss';

/**
 * An array of all the available {@link SimpleBreakpoint}-s.
 *
 * This array represents the full breakpoint coverage (state when component covers all breakpoints).
 * This array is also used for calculating missing breakpoints to achieve said full coverage.
 * The order of this array is crucial since the logic of expanding -min and -max breakpoints
 * depends on it.
 */
export const simpleBreakpoints = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl'] as const;

/**
 * The "atom" breakpoints (The simplest breakpoints), no fancy names like desktop or phone,
 * and no -min or -max.
 */
export type SimpleBreakpoint = typeof simpleBreakpoints[number];

/** An array of {@link SimpleBreakpoint}-s. Shorter than using the Array template. */
export type SimpleBreakpoints = Array<SimpleBreakpoint>;

/**
 * A mapping between all available {@link NamedBreakpoint}-s and {@link SimpleBreakpoint}-s.
 */
export const namedBreakpoints = {
  phone: ['xxs', 'xs'],
  tablet: ['sm', 'md'],
  desktop: ['lg', 'xl']
} as const;

/**
 * Named breakpoints. These breakpoints are for developer's convenience and
 * represent an array of {@link SimpleBreakpoint}-s behind the scenes.
 */
export type NamedBreakpoint = keyof typeof namedBreakpoints;

/**
 * Range breakpoints. A combination of all {@link SimpleBreakpoint}-s + `min` or `max`.
 * With two exceptions:
 *
 * `xxs-min` doesn't exist since every screen is at least xxs anyway.
 * `xl-max` doesn't exist since every screen is at most xl anyway.
 */
export type RangeBreakpoint = Exclude<
  `${SimpleBreakpoint}-min` | `${SimpleBreakpoint}-max`,
  'xxs-min' | 'xl-max'
>;

/**
 * A mapping between all available {@link RangeBreakpoint}-s and equivalent arrays of {@link SimpleBreakpoint}-s.
 *
 * @example ```ts
 * 'md-max': ['xxs', 'xs', 'sm', 'md'],
 * 'lg-min': ['lg', 'xl']
 * ```
 * This array gets filled dynamically therefore doesn't have a static (constant) type definition.
 */
export const rangeBreakpoints = new Map<RangeBreakpoint, SimpleBreakpoints>();

/**
 * All the available breakpoints that can be passed to the `<Breakpoint>` component.
 * Any value that is not a {@link SimpleBreakpoint} will end up being transformed
 * to {@link SimpleBreakpoint}-s for simplicity.
 * @see {@link SimpleBreakpoint}
 * @see {@link RangeBreakpoint}
 * @see {@link NamedBreakpoint}
 */
export type MediaBreakpoint =
  | RangeBreakpoint
  | SimpleBreakpoint
  | NamedBreakpoint;

/**
 * An array of {@link MediaBreakpoint}-s.
 * This (an array) can also get passed to the `<Breakpoint>` component.
 */
export type MediaBreakpoints = Array<MediaBreakpoint>;

/**
 * Represents a mapping between all {@link SimpleBreakpoint}-s and
 * the beginning of the breakpoint in pixels.
 */
export type BreakpointValues = Record<SimpleBreakpoint, number>;

/**
 * The `<Breakpoints>`, `<Breakpoint>` and `<Default>` components are just an interface
 * to describe the breakpoints with JSX. At the end, we have an array with every element
 * having breakpoint(s) and a tree (React element) that should visible for those breakpoints.
 */
export interface IRawBreakpoint {
  /** The breakpoints for which the tree should be rendered. */
  breakpoints: MediaBreakpoints;

  /** The tree. */
  tree: ReactNode;
}

/**
 * Parses an SCSS dimension in pixels.
 *
 * @param value - An SCSS dimension with px unit (ie. '1024px').
 * @returns The number of pixels.
 * @throws When the breakpoint cannot be parsed.
 */
function parseScssPixels(value: string): number {
  // '0' is a special case, as it is commonly written without the unit ('0' instead of '0px')
  if (value === '0') {
    return 0;
  }

  if (!value.endsWith('px')) {
    throw new Error(
      `Cannot parse breakpoint "${value}", since it doesn't end with "px"`
    );
  }

  const px = parseInt(value.slice(0, -2), 10);
  if (!Number.isFinite(px) || Number.isNaN(px)) {
    throw new Error(
      `Cannot parse breakpoint "${value}", since it's not a valid number. Tried "${px}"`
    );
  }

  return px;
}

/**
 * Parse breakpoint values from the SCSS variables.
 *
 * @param scssVariables - The SCSS variables.
 * @returns The breakpoints.
 */
function parseScssBreakpoints(
  scssVariables: Record<string, string>
): BreakpointValues {
  return {
    xxs: parseScssPixels(scssVariables.breakpointXxs),
    xs: parseScssPixels(scssVariables.breakpointXs),
    sm: parseScssPixels(scssVariables.breakpointSm),
    md: parseScssPixels(scssVariables.breakpointMd),
    lg: parseScssPixels(scssVariables.breakpointLg),
    xl: parseScssPixels(scssVariables.breakpointXl)
  };
}

/**
 * The pixel values for all the breakpoints.
 * The pixel values represent the beginning of a breakpoint, so
 * lg: 1024 means the lg breakpoint starts from 1024 pixels.
 */
export const breakpointValues = parseScssBreakpoints({
  breakpointXxs,
  breakpointXs,
  breakpointSm,
  breakpointMd,
  breakpointLg,
  breakpointXl
});

/**
 * Fill the rangeBreakpoints array by generating all combinations of breakpoints
 * with min and max.
 *
 * So expand md-max into [xxs, xs, sm, md].
 * Or expand lg-min into [lg, xl].
 */
simpleBreakpoints.forEach((breakpoint, index) => {
  const min = `${breakpoint}-min`;
  const max = `${breakpoint}-max`;

  // All of the breakpoints before this one, this included.
  // (So xxs-min doesn't exist, since it is the lowest breakpoint)
  if (min !== 'xxs-min') {
    rangeBreakpoints.set(
      min as RangeBreakpoint,
      simpleBreakpoints.slice(index)
    );
  }

  // All of the breakpoints after this one, this included.
  // (So xl-max doesn't exist, since it is the highest breakpoint)
  if (max !== 'xl-max') {
    rangeBreakpoints.set(
      max as RangeBreakpoint,
      simpleBreakpoints.slice(0, index + 1)
    );
  }
});
