import { useContext } from 'react';
import {
  type IRouterEventManager,
  RouterEventManagerContext
} from './internal/RouterEventManagerContext';

export type { IRouterEventManager };

/**
 * Retrieves the router event manager.
 * @returns The router event manager.
 */
export function useRouterEventManager(): IRouterEventManager {
  return useContext(RouterEventManagerContext);
}
