'use client';

/* eslint-disable jsdoc/require-description-complete-sentence  -- Comments in this file are full of mathematical expression, that don't need to end with a period. */

import {
  breakpointLg,
  breakpointMd,
  breakpointSm,
  breakpointXl,
  breakpointXs,
  breakpointXxs
} from '@/styles/variables.module.scss';
import { Nullable } from '@/type-utils';
import { useEffect, useState } from 'react';

/**
 * Result of querying the breakpoints.
 */
interface IBreakpointsResult {
  /** Width < $breakpoint-sm  */
  isPhone: boolean;

  /** $breakpoint-sm <= Width < $breakpoint-lg  */
  isTablet: boolean;

  /** $breakpoint-lg <= Width  */
  isDesktop: boolean;

  /** $breakpoint-xs <= Width */
  isXsMin: boolean;

  /** $breakpoint-ms <= Width */
  isSmMin: boolean;

  /** $breakpoint-md <= Width */
  isMdMin: boolean;

  /** $breakpoint-lg <= Width */
  isLgMin: boolean;

  /** $breakpoint-xl <= Width */
  isXlMin: boolean;

  /** Width < $breakpoint-xs */
  isXxsMax: boolean;

  /** Width < $breakpoint-sm */
  isXsMax: boolean;

  /** Width < $breakpoint-md */
  isSmMax: boolean;

  /** Width < $breakpoint-lg */
  isMdMax: boolean;

  /** Width < $breakpoint-xl */
  isLgMax: boolean;

  /** Width < $breakpoint-xs */
  isXxs: boolean;

  /** $breakpoint-xs <= Width < $breakpoint-sm */
  isXs: boolean;

  /** $breakpoint-sm <= Width < $breakpoint-md */
  isSm: boolean;

  /** $breakpoint-md <= Width < $breakpoint-lg */
  isMd: boolean;

  /** $breakpoint-lg <= Width < $breakpoint-xl */
  isLg: boolean;

  /** $breakpoint-xl <= Width */
  isXl: boolean;
}

/**
 * Breakpoints.
 */
interface IBreakpoints {
  /**
   * XXS breakpoint in px.
   */
  xxs: number;

  /**
   * XS breakpoint in px.
   */
  xs: number;

  /**
   * SM breakpoint in px.
   */
  sm: number;

  /**
   * MD breakpoint in px.
   */
  md: number;

  /**
   * LG breakpoint in px.
   */
  lg: number;

  /**
   * XL breakpoint in px.
   */
  xl: number;
}

/**
 * Parses an SCSS dimension in pixels.
 *
 * @param value - An SCSS dimension with px unit (ie. '1024px').
 * @returns The number of pixels.
 */
function parseScssPixels(value: string): Nullable<number> {
  // '0' is a special case, as it is commonly written without the unit ('0' instead of '0px')
  if (value === '0') {
    return 0;
  }

  const re = /(\d+)(\s*)px/;
  const reResult = re.exec(value);
  if (!reResult) {
    return null;
  }
  const theInt = parseInt(reResult[1], 10);
  if (!Number.isFinite(theInt) || Number.isNaN(theInt)) {
    return null;
  }
  return theInt;
}

/**
 * Parse breakpoint values from the SCSS variables.
 *
 * @param scssVariables - The SCSS variables.
 * @returns The breakpoints.
 * @throws Throws when any of the breakpoints cannot be parsed.
 */
function parseScssBreakpoints(
  scssVariables: Record<string, string>
): IBreakpoints {
  const result: Record<string, Nullable<number>> = {
    xxs: parseScssPixels(scssVariables.breakpointXxs),
    xs: parseScssPixels(scssVariables.breakpointXs),
    sm: parseScssPixels(scssVariables.breakpointSm),
    md: parseScssPixels(scssVariables.breakpointMd),
    lg: parseScssPixels(scssVariables.breakpointLg),
    xl: parseScssPixels(scssVariables.breakpointXl)
  };

  // Validate
  const isValid = (breakpoints: typeof result): IBreakpoints => {
    for (const breakpoint in result) {
      if (typeof result[breakpoint] !== 'number') {
        throw new Error(
          `Cannot parse breakpoint "${breakpoint}". ` +
            `Value for breakpoint was "${result[breakpoint]}"`
        );
      }
    }
    return breakpoints as unknown as IBreakpoints;
  };

  return isValid(result);
}

const breakpoints = parseScssBreakpoints({
  breakpointXxs,
  breakpointXs,
  breakpointSm,
  breakpointMd,
  breakpointLg,
  breakpointXl
});

/**
 * Compare screenWidth to given breakpoints.
 *
 * @param screenWidth - Screen width in pixels.
 * @returns Evaluated breakpoint results.
 */
export function evaluateBreakpoints(screenWidth: number): IBreakpointsResult {
  return {
    isPhone: screenWidth < breakpoints.sm,
    isTablet: breakpoints.sm <= screenWidth && screenWidth < breakpoints.lg,
    isDesktop: breakpoints.lg <= screenWidth,

    isXsMin: breakpoints.xs <= screenWidth,
    isSmMin: breakpoints.sm <= screenWidth,
    isMdMin: breakpoints.md <= screenWidth,
    isLgMin: breakpoints.lg <= screenWidth,
    isXlMin: breakpoints.xl <= screenWidth,

    isXxsMax: screenWidth < breakpoints.xs,
    isXsMax: screenWidth < breakpoints.sm,
    isSmMax: screenWidth < breakpoints.md,
    isMdMax: screenWidth < breakpoints.lg,
    isLgMax: screenWidth < breakpoints.xl,

    isXxs: screenWidth < breakpoints.xs,
    isXs: breakpoints.xs <= screenWidth && screenWidth < breakpoints.sm,
    isSm: breakpoints.sm <= screenWidth && screenWidth < breakpoints.md,
    isMd: breakpoints.md <= screenWidth && screenWidth < breakpoints.lg,
    isLg: breakpoints.lg <= screenWidth && screenWidth < breakpoints.xl,
    isXl: breakpoints.xl <= screenWidth
  };
}

/**
 * Provide evaluated breakpoints against the current window size.
 * @returns The evaluated breakpoints.
 * @example ```tsx
 * export const MyReactComponent = () => {
 *   const { isPhone } = useBreakpoints();
 *   return isPhone ? <PhoneLayout /> : <DefaultLayout />;
 * }
 * ```
 */
export function useBreakpoints(): IBreakpointsResult {
  const [result, setResult] = useState(
    evaluateBreakpoints(
      typeof window !== 'undefined' ? window.innerWidth : 1024
    )
  );

  useEffect(() => {
    const onResize = (): void => {
      setResult(evaluateBreakpoints(window.innerWidth));
    };

    if (window) {
      window.addEventListener('resize', onResize);
      return () => {
        window.removeEventListener('resize', onResize);
      };
    }

    return () => {};
  }, []);

  return result;
}
