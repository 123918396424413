'use client';

import { useRef } from 'react';

/**
 * `useIsFirstRender` is a hook that returns a boolean indicating if the
 * component is being rendered and mounted for the first time.
 * @returns `true` if the component is being rendered for the first time, `false` otherwise.
 */
export function useIsFirstRender(): boolean {
  const ref = useRef(true);

  if (ref.current) {
    ref.current = false;
    return true;
  }

  return false;
}
