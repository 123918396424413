'use client';

import { msg, msgf } from '@/services/isomorphic/I18NService';
import { useLocale } from './useLocale';

/**
 * A hook that gets msg and msgf functions with the current locale using the
 * useLocale hook.
 * @returns The msg and msgf functions with the current locale.
 */
export function useLocaleMessage(): [typeof msg, typeof msgf] {
  // Get the locale however it can be accessed.
  const locale = useLocale();

  // Assemble functionally similar methods to represent the msg and msgf functions
  // but with the locale included.
  const msgL = (msgID: Parameters<typeof msg>[0]): ReturnType<typeof msg> =>
    msg(msgID, locale);
  const msgfL = (
    msgID: Parameters<typeof msgf>[0],
    formatOpts: Parameters<typeof msgf>[1]
  ): ReturnType<typeof msgf> => msgf(msgID, formatOpts, locale);

  return [msgL as unknown as typeof msg, msgfL as unknown as typeof msgf];
}
